// extracted by mini-css-extract-plugin
export var approvalIcon = "ProviderEditLocationApprovalStatus__approvalIcon__uJUmj";
export var approvalStatusContainer = "ProviderEditLocationApprovalStatus__approvalStatusContainer__mbkMP";
export var approvalTextContainer = "ProviderEditLocationApprovalStatus__approvalTextContainer__rhx3g";
export var approvedText = "ProviderEditLocationApprovalStatus__approvedText__ZO3GG";
export var column = "ProviderEditLocationApprovalStatus__column__HOviD";
export var flex = "ProviderEditLocationApprovalStatus__flex__reaLw";
export var flexColumn = "ProviderEditLocationApprovalStatus__flexColumn__wObHU";
export var gap1 = "ProviderEditLocationApprovalStatus__gap1__W1JRn";
export var gap2 = "ProviderEditLocationApprovalStatus__gap2__ccvbG";
export var gap3 = "ProviderEditLocationApprovalStatus__gap3__UvA6N";
export var gap4 = "ProviderEditLocationApprovalStatus__gap4__PjaRn";
export var gap5 = "ProviderEditLocationApprovalStatus__gap5__vHGA4";
export var pendingText = "ProviderEditLocationApprovalStatus__pendingText__taZJX";
export var row = "ProviderEditLocationApprovalStatus__row__obsqo";