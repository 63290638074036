// extracted by mini-css-extract-plugin
export var actionButtonsContainer = "ProviderEditActiveButton__actionButtonsContainer__N4pjk";
export var column = "ProviderEditActiveButton__column__O2QVS";
export var flex = "ProviderEditActiveButton__flex__Vd_1s";
export var flexColumn = "ProviderEditActiveButton__flexColumn__zNpL4";
export var gap1 = "ProviderEditActiveButton__gap1__esNxq";
export var gap2 = "ProviderEditActiveButton__gap2__n36zK";
export var gap3 = "ProviderEditActiveButton__gap3__DROfj";
export var gap4 = "ProviderEditActiveButton__gap4__ah_zY";
export var gap5 = "ProviderEditActiveButton__gap5__QA1PJ";
export var inactiveText = "ProviderEditActiveButton__inactiveText__JoGQ3";
export var row = "ProviderEditActiveButton__row__YXtxX";
export var toggleContainer = "ProviderEditActiveButton__toggleContainer__AjjGI";