// extracted by mini-css-extract-plugin
export var column = "ProviderEditProviderUsers__column__FC2gw";
export var deleteConfirmationButtons = "ProviderEditProviderUsers__deleteConfirmationButtons__Balms";
export var deleteConfirmationDialog = "ProviderEditProviderUsers__deleteConfirmationDialog__PMawl";
export var deleteConfirmationOverlay = "ProviderEditProviderUsers__deleteConfirmationOverlay__NpIzg";
export var deleteConfirmationText = "ProviderEditProviderUsers__deleteConfirmationText__SsqQR";
export var dropdown = "ProviderEditProviderUsers__dropdown__wzvzQ";
export var dropdownBody = "ProviderEditProviderUsers__dropdownBody__RbnqK";
export var dropdownBodyLink = "ProviderEditProviderUsers__dropdownBodyLink__NpE43";
export var dropdownBodyRow = "ProviderEditProviderUsers__dropdownBodyRow__p7c8b";
export var flex = "ProviderEditProviderUsers__flex__Iw0gj";
export var flexColumn = "ProviderEditProviderUsers__flexColumn__U6ABd";
export var gap1 = "ProviderEditProviderUsers__gap1__ouyK1";
export var gap2 = "ProviderEditProviderUsers__gap2__DzNaJ";
export var gap3 = "ProviderEditProviderUsers__gap3__iLlQO";
export var gap4 = "ProviderEditProviderUsers__gap4__ep95y";
export var gap5 = "ProviderEditProviderUsers__gap5__KXMyz";
export var header = "ProviderEditProviderUsers__header__GbguD";
export var label = "ProviderEditProviderUsers__label__mlqyl";
export var pagination = "ProviderEditProviderUsers__pagination__bn3UU";
export var profilePic = "ProviderEditProviderUsers__profilePic__XlBuE";
export var row = "ProviderEditProviderUsers__row__bXZBe";
export var table = "ProviderEditProviderUsers__table__mxprg";