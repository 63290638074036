// extracted by mini-css-extract-plugin
export var actionButtonsContainer = "ProviderEditAgreementButton__actionButtonsContainer__VgIdi";
export var column = "ProviderEditAgreementButton__column__Ghulv";
export var flex = "ProviderEditAgreementButton__flex__gYl_h";
export var flexColumn = "ProviderEditAgreementButton__flexColumn__YO3kt";
export var gap1 = "ProviderEditAgreementButton__gap1__UgTwg";
export var gap2 = "ProviderEditAgreementButton__gap2__IPjnc";
export var gap3 = "ProviderEditAgreementButton__gap3__g7VVM";
export var gap4 = "ProviderEditAgreementButton__gap4__y5QLC";
export var gap5 = "ProviderEditAgreementButton__gap5__JmPpV";
export var inactiveText = "ProviderEditAgreementButton__inactiveText___PCJc";
export var row = "ProviderEditAgreementButton__row__GcCOC";
export var toggleContainer = "ProviderEditAgreementButton__toggleContainer__xdeKc";