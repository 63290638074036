// extracted by mini-css-extract-plugin
export var adminLink = "ProviderEditTabs__adminLink__Wlgyv";
export var barContainer = "ProviderEditTabs__barContainer__ByQrF";
export var barWrapper = "ProviderEditTabs__barWrapper__RImGs";
export var column = "ProviderEditTabs__column__IADCS";
export var flex = "ProviderEditTabs__flex__HAhOg";
export var flexColumn = "ProviderEditTabs__flexColumn__ObFlJ";
export var gap1 = "ProviderEditTabs__gap1__N9yyQ";
export var gap2 = "ProviderEditTabs__gap2__vd0ai";
export var gap3 = "ProviderEditTabs__gap3__tdBsy";
export var gap4 = "ProviderEditTabs__gap4__hk04Z";
export var gap5 = "ProviderEditTabs__gap5__KVJrP";
export var linkContainer = "ProviderEditTabs__linkContainer__LC6pM";
export var linkItem = "ProviderEditTabs__linkItem__N7h2I";
export var row = "ProviderEditTabs__row__SJgZ0";
export var subLinkItem = "ProviderEditTabs__subLinkItem__IMdcP";
export var underlinedLink = "ProviderEditTabs__underlinedLink__yIW4E";