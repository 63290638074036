// extracted by mini-css-extract-plugin
export var approvalIcon = "ProviderEditApprovalStatus__approvalIcon__h_g2J";
export var approvalStatusContainer = "ProviderEditApprovalStatus__approvalStatusContainer__Ndq41";
export var approvalTextContainer = "ProviderEditApprovalStatus__approvalTextContainer__GqXnn";
export var approvedText = "ProviderEditApprovalStatus__approvedText__kZDpW";
export var column = "ProviderEditApprovalStatus__column__Lqvmg";
export var flex = "ProviderEditApprovalStatus__flex__PycKA";
export var flexColumn = "ProviderEditApprovalStatus__flexColumn__fJM7H";
export var gap1 = "ProviderEditApprovalStatus__gap1__djRDY";
export var gap2 = "ProviderEditApprovalStatus__gap2__dokgl";
export var gap3 = "ProviderEditApprovalStatus__gap3__J91Lc";
export var gap4 = "ProviderEditApprovalStatus__gap4__HwtWv";
export var gap5 = "ProviderEditApprovalStatus__gap5__gsjp4";
export var pendingText = "ProviderEditApprovalStatus__pendingText__RstZx";
export var row = "ProviderEditApprovalStatus__row__ajiUd";