// extracted by mini-css-extract-plugin
export var actionButtonsContainer = "ProviderEditLocationStickyBar__actionButtonsContainer__mQDVs";
export var approvalContainer = "ProviderEditLocationStickyBar__approvalContainer__nwYhp";
export var approveButton = "ProviderEditLocationStickyBar__approveButton__yml5A";
export var column = "ProviderEditLocationStickyBar__column__AC59R";
export var container = "ProviderEditLocationStickyBar__container__ZJXn4";
export var flex = "ProviderEditLocationStickyBar__flex__hGR9i";
export var flexColumn = "ProviderEditLocationStickyBar__flexColumn__ltp8Y";
export var gap1 = "ProviderEditLocationStickyBar__gap1__M2ohD";
export var gap2 = "ProviderEditLocationStickyBar__gap2__bTwK6";
export var gap3 = "ProviderEditLocationStickyBar__gap3__7ZvuM";
export var gap4 = "ProviderEditLocationStickyBar__gap4__fSyX2";
export var gap5 = "ProviderEditLocationStickyBar__gap5__chYre";
export var inactiveText = "ProviderEditLocationStickyBar__inactiveText__buCwJ";
export var innerContainer = "ProviderEditLocationStickyBar__innerContainer__IQaur";
export var row = "ProviderEditLocationStickyBar__row__O7P8w";
export var saveButton = "ProviderEditLocationStickyBar__saveButton__T1cm5";
export var toggleContainer = "ProviderEditLocationStickyBar__toggleContainer__a2HcA";