// extracted by mini-css-extract-plugin
export var column = "ProviderEditContainers__column__Og4SD";
export var flex = "ProviderEditContainers__flex__o6q_D";
export var flexColumn = "ProviderEditContainers__flexColumn__uyjFM";
export var gap1 = "ProviderEditContainers__gap1__Fr3V8";
export var gap2 = "ProviderEditContainers__gap2__u_hyY";
export var gap3 = "ProviderEditContainers__gap3__dyAHa";
export var gap4 = "ProviderEditContainers__gap4__kCdqz";
export var gap5 = "ProviderEditContainers__gap5__ukhpn";
export var header = "ProviderEditContainers__header__HeXtQ";
export var headerTabsContainer = "ProviderEditContainers__headerTabsContainer__xBR28";
export var linkContainer = "ProviderEditContainers__linkContainer__Grvmd";
export var row = "ProviderEditContainers__row__pMQfj";
export var subnavContainer = "ProviderEditContainers__subnavContainer___FwJY";
export var tabItem = "ProviderEditContainers__tabItem__q7JN0";
export var title = "ProviderEditContainers__title__XILj2";
export var underlinedLink = "ProviderEditContainers__underlinedLink__mo2Xw";
export var viewLive = "ProviderEditContainers__viewLive__Jj_am";