// extracted by mini-css-extract-plugin
export var actionButtonsContainer = "ProviderEditLocationActiveButton__actionButtonsContainer__nQfEI";
export var column = "ProviderEditLocationActiveButton__column__pSib9";
export var flex = "ProviderEditLocationActiveButton__flex__IHcyD";
export var flexColumn = "ProviderEditLocationActiveButton__flexColumn__yz7w4";
export var gap1 = "ProviderEditLocationActiveButton__gap1__XvEmh";
export var gap2 = "ProviderEditLocationActiveButton__gap2__VPhCj";
export var gap3 = "ProviderEditLocationActiveButton__gap3__ZWofh";
export var gap4 = "ProviderEditLocationActiveButton__gap4__bSH06";
export var gap5 = "ProviderEditLocationActiveButton__gap5__j0aGF";
export var inactiveText = "ProviderEditLocationActiveButton__inactiveText__TJ8me";
export var row = "ProviderEditLocationActiveButton__row__fGqvV";
export var toggleContainer = "ProviderEditLocationActiveButton__toggleContainer__qge0t";