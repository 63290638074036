// extracted by mini-css-extract-plugin
export var column = "ProviderEditLocationsOptions__column__AsXQV";
export var flex = "ProviderEditLocationsOptions__flex__c_CIB";
export var flexColumn = "ProviderEditLocationsOptions__flexColumn__3GAz6";
export var gap1 = "ProviderEditLocationsOptions__gap1__kQ3yy";
export var gap2 = "ProviderEditLocationsOptions__gap2__AQvDT";
export var gap3 = "ProviderEditLocationsOptions__gap3__cUKc9";
export var gap4 = "ProviderEditLocationsOptions__gap4__wQ5Rw";
export var gap5 = "ProviderEditLocationsOptions__gap5__anybh";
export var popoverBody = "ProviderEditLocationsOptions__popoverBody___Ocbw";
export var popoverBodyLink = "ProviderEditLocationsOptions__popoverBodyLink__Bk6Ge";
export var popoverBodyLinkIcon = "ProviderEditLocationsOptions__popoverBodyLinkIcon__SkDn4";
export var row = "ProviderEditLocationsOptions__row__bS0Ex";