// extracted by mini-css-extract-plugin
export var actionButtonsContainer = "ProviderEdit__actionButtonsContainer__zuKBo";
export var column = "ProviderEdit__column__qSEIE";
export var dropdownBody = "ProviderEdit__dropdownBody__NvSOY";
export var dropdownBodyRow = "ProviderEdit__dropdownBodyRow__iPJSB";
export var dropdownTrigger = "ProviderEdit__dropdownTrigger__aXMDB";
export var flex = "ProviderEdit__flex__vUsXy";
export var flexColumn = "ProviderEdit__flexColumn__cZHCu";
export var gap1 = "ProviderEdit__gap1__VlAvp";
export var gap2 = "ProviderEdit__gap2__HsC7z";
export var gap3 = "ProviderEdit__gap3__Nw4em";
export var gap4 = "ProviderEdit__gap4__I8f8O";
export var gap5 = "ProviderEdit__gap5__GkJ8V";
export var inactiveText = "ProviderEdit__inactiveText___mJ3U";
export var label = "ProviderEdit__label__XX4Un";
export var layout = "ProviderEdit__layout__w24Kk";
export var row = "ProviderEdit__row__o4TC1";
export var searchField = "ProviderEdit__searchField__kkUrZ";
export var searchIcon = "ProviderEdit__searchIcon__DhkdL";
export var toggleContainer = "ProviderEdit__toggleContainer__lpyIK";