// extracted by mini-css-extract-plugin
export var actionButtonsContainer = "LocationEditToggleButtons__actionButtonsContainer__czq7A";
export var column = "LocationEditToggleButtons__column__OrYIA";
export var flex = "LocationEditToggleButtons__flex__lW7Sq";
export var flexColumn = "LocationEditToggleButtons__flexColumn__TEHNz";
export var gap1 = "LocationEditToggleButtons__gap1__EPSGH";
export var gap2 = "LocationEditToggleButtons__gap2__uMKjh";
export var gap3 = "LocationEditToggleButtons__gap3__Ube_S";
export var gap4 = "LocationEditToggleButtons__gap4__XNzWX";
export var gap5 = "LocationEditToggleButtons__gap5__QP_x1";
export var inactiveText = "LocationEditToggleButtons__inactiveText__lweN1";
export var row = "LocationEditToggleButtons__row__hyD1v";
export var toggleContainer = "LocationEditToggleButtons__toggleContainer__WQ_rS";